import React from 'react'
import {Layout, InsurancesCarousel, WellnessBanner, FeaturesCard, WhyUsCard, MayInterestYouCards} from 'widgets'
import {SEO, Input, IntlTelInput, Button, Select, Datepicker} from 'components'
import {BannerHome, BannerHomeMovil, BannerHomeMovil2} from 'images'
import {IntlTelCountry, QuoteRequest} from 'models'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from 'reducers'
import {navigate} from 'gatsby'
import { DESTINIES as Destinies, quitLoading, setLoading, showError } from 'utils'
import { CandidateService } from 'services'
import moment from 'moment';
import { Cobertura } from '../models/responses/Coverage';
const isBrowser = typeof window !== "undefined";
let detectMobil:boolean =  false;
if(isBrowser){
    if ( 
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) 
    ) {
            detectMobil = true;
        }
        else{
          detectMobil = false;
        }
        console.log('detectMobil', detectMobil);
}
const defaultAge = 18

type Form = {
    name: string,
    lastname: string,
    email: string,
    country: null | IntlTelCountry,
    destiny_id?: string | undefined,
    country_phone: null | string,
    since:Date | undefined,
    until:Date | undefined,
    passengers: number[] | undefined,
    
}
type ValidationError = {
    // name?: boolean,
    // lastname?: boolean,
    email?: boolean,
    country?: boolean,
    destiny_id?: boolean,
    country_phone?: boolean,
    since?:boolean,
    until?: boolean,
    
}
const DESTINIES = Destinies.map(element => {
    return {
        label: element.es,
        value: element.slug
    }
})
const Home = () => {
    const quoteRequest: QuoteRequest | null = useSelector((state: RootState) => state.quoteRequest)
    const dispatch = useDispatch()
    const IFormState: Form = {
        name: 'Viajero',
        lastname: 'Compara',
        email: '',
        country: null,
        destiny_id: DESTINIES[0].value,
        country_phone: '',
        since: moment().toDate(),
        until: moment().toDate(),
        passengers: [defaultAge],
    }
    
    const [form, setForm] = React.useState<Form>(IFormState)
    const change = (key: keyof Form, value: string | Date) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    
    const defaultIntlClass = 'intl-tel-input input-purple form-control'
    const invalidIntlClass = defaultIntlClass  +' invalid-feedback '
    const [intlClassName, setIntlClassName] = React.useState<string>(defaultIntlClass)
    const [validationError, setValidationError] = React.useState<ValidationError>({})
    const onChangeIntl = (isCorrect: boolean, country_phone: string, country: IntlTelCountry) => {
        let _form: Form = {
            ... form,
            country,
            country_phone
        }
        setForm(_form)
        if(country && country_phone){
            setIntlClassName(defaultIntlClass)
        }else{
            setIntlClassName(invalidIntlClass)
        }
        let _validationError = {... validationError}
        if(country_phone == ''){
            _validationError['country_phone'] = true
        }
        else{
            delete _validationError['country_phone']
            
        }
        setValidationError(_validationError)
    }
    
    const onSubmit = async () => {
        try {
            setLoading()
            const _form = {
                ... form,
                country_phone: '+'+form.country?.dialCode+form.country_phone
            }
            console.log(_form);
            const res = await CandidateService.save(_form)
            dispatch({
                type: 'SET_QUOTE_REQUEST',
                payload: form
            })
            // navigate('/segundo-paso?candidate='+res.id_sql)
            navigate('/buscar?candidate='+res.id_sql)
        } catch (error:any) {
            showError(error?.response?.data?.message)
        }finally{
            quitLoading()
        }
    }
    const isDisabled = (): boolean => {
        let disabled = !form.country || !form.country_phone || !form.email
        let _validationError = {}
        for (const key in form) {
            //@ts-ignore
            if(!form[key]){
                //@ts-ignore
                _validationError[key] = true
            }
        }
        const haveSymbol = form.email.includes('@')
        if((Object.values(_validationError).length > 0) || (form.email && !disabled && !haveSymbol)){
            disabled = true
        }
        return disabled
    }
    const onBlurField = (key: keyof ValidationError) => {
        const value = form[key]
        let _validationError = {... validationError}
        if((key === 'email' && !form.email.includes('@')) || (!value && !validationError[key])){
            _validationError[key] = true
        }else{
            delete _validationError[key]
        }
        setValidationError(_validationError)
    }

    const onFocusField = (key: keyof ValidationError) => {
        let _validationError = {... validationError}
        delete _validationError[key]
        setValidationError(_validationError)
    }

    return(
        <Layout>
            <SEO
                title="Compara seguros de viaje y elige la mejor cobertura internacional"
                description="El momento ha llegado y ¿aún no sabes qué seguro de viaje elegir? Compara y elige las mejores coberturas para tu viaje en Compara Assist rápido, fácil y seguro.."
                keywords="Seguro de viaje, comparador de seguros, protege tu viaje, por qué es importante un seguro de viaje, accidentes, pérdida de pasaporte, enfermedades ,robo en viaje, cancelación de viajes, covid, seguro estudiantil, tercera edad, mochilero, estudiante,"
            />
            <div className="new-home">
                <div
                    className="banner"
                    style={{
                        backgroundImage: !detectMobil ? `url(${BannerHome})` : 'none',
                        backgroundColor: '#451f69'
                    }}
                >
                    {
                        detectMobil 
                        ? 
                        <span>
                            <img src={BannerHomeMovil} style={{ width: '100%', maxHeight: '150px'}}/>
                        </span>
                        : ''
                    }
                    <div className="form">
                        <div className="w-100 text-center form-cotiza">
                            <h4 className="text-green pt-2">
                                ¡Iniciemos!
                            </h4>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        {/* <Input
                                            name="name"
                                            className="input-purple"
                                            placeholder="Tu nombre aqui"
                                            required
                                            hasError={validationError.name}
                                            onBlur={() => onBlurField('name')}
                                            onFocus={() => onFocusField('name')}
                                            onChange={(value: string) => change('name', value)}
                                            value={form.name}
                                        /> */}
                                        <Select
                                            name="destiny_id"
                                            value={form.destiny_id || ''}
                                            options={DESTINIES}
                                            placeholder="Destino"
                                            onChange={(value: string) => change('destiny_id', value)}
                                            required
                                            className="input-purple text-dark"
                                            hasError={validationError.destiny_id}
                                            onBlur={() => onBlurField('destiny_id')}
                                            onFocus={() => onFocusField('destiny_id')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        {/* <Input
                                            className="input-purple"
                                            name="lastname"
                                            placeholder="Tu apellido aqui"
                                            onChange={(value: string) => change('lastname', value)}
                                            value={form.lastname}
                                            required
                                            hasError={validationError.lastname}
                                            onBlur={() => onBlurField('lastname')}
                                            onFocus={() => onFocusField('lastname')}
                                        /> */}
                                        <Datepicker
                                            minDate={moment().toDate()}
                                            onChange={(value: Date) => change('since', value)}
                                            placeholder="Fecha de salida"
                                            labelColor="dark"
                                            value={form.since}
                                            required
                                            hasError={validationError.since}
                                            onBlur={() => onBlurField('since')}
                                            onFocus={() => onFocusField('since')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        {/* <Input
                                            className="input-purple"
                                            name="lastname"
                                            placeholder="Tu apellido aqui"
                                            onChange={(value: string) => change('lastname', value)}
                                            value={form.lastname}
                                            required
                                            hasError={validationError.lastname}
                                            onBlur={() => onBlurField('lastname')}
                                            onFocus={() => onFocusField('lastname')}
                                        /> */}
                                        <Datepicker
                                        minDate={form.since}
                                        onChange={(value: Date) => change('until', value)}
                                        placeholder="Fecha de regreso"
                                        labelColor="dark"
                                        value={form.until}
                                        required
                                        hasError={validationError.until}
                                        onBlur={() => onBlurField('until')}
                                        onFocus={() => onFocusField('until')}
                                    />
                                    </div>
                                    <div className="col-6">
                                        <Input
                                            className="input-purple"
                                            name="email"
                                            placeholder="Tu email aqui"
                                            type="email"
                                            onChange={(value: string) => change('email', value)}
                                            value={form.email}
                                            required
                                            hasError={validationError.email}
                                            onBlur={() => onBlurField('email')}
                                            onFocus={() => onFocusField('email')}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <div className="w-100">
                                            <IntlTelInput
                                                onChange={onChangeIntl}
                                                placeholder="WhatsApp"
                                                className={intlClassName}
                                            />
                                        </div>
                                    </div>
                                    <div className="container pl-0 pr-0">
                                        <div className="button-container pl-0 pr-0">
                                            <Button
                                                disabled={isDisabled()}
                                                onClick={() => onSubmit()}
                                                label="Cotizar"
                                                sublabel="Planes desde 1.90 USD el día"
                                                className="success w-100 text-white btn-pill"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        detectMobil 
                        ? 
                        <span style={{
                            backgroundImage: detectMobil ? `url(${BannerHomeMovil2})` : 'none',
                            backgroundColor: '#451f69'
                            
                        }}>
                            <div className="m-auto text-center p-2 text-white px-4" 
                            style={{fontSize:'28px', fontWeight:'bold'}}>
                            ! Viaja Traquilo y Seguro !
                            </div>
                            <div className="m-auto text-left p-2 text-white">
                                - Cobertura Covid <br />
                                - Cumplimos con todas las restricciones de los países <br />
                                - Planes acorde a tu presupuesto <br /><br />
                            </div>
                        </span>
                        : ''
                    }
                </div>
                <FeaturesCard
                />
                <WhyUsCard
                />
                <div className="w-100 insurance-container p-4">
                    <div className="container">
                        <InsurancesCarousel
                        
                        />
                    </div>
                </div>
                <div className="wellness-banners p-2">
                    <WellnessBanner

                    />
                </div>
               <MayInterestYouCards
               />
            </div>
        </Layout>
    )
}

export default Home